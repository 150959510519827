export const users = [
    {
        "email": "florian.baader@selectcode.de",
        "name": "Florian Baader",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/944523-161c844b8a9d10.png"
    },
    {
        "email": "stephan.le@selectcode.de",
        "name": "Stephan Le",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/945955-162d971a670894.png"
    },
    {
        "email": "matthias.weirich@selectcode.de",
        "name": "Matthias Weirich",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/951239-1615fe11d267d0.png"
    },
    {
        "email": "niclas.schuemann@selectcode.de",
        "name": "Niclas Schümann",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/951241-15f983d0080f71.png"
    },
    {
        "email": "julia.raasch@selectcode.de",
        "name": "Julia Raasch",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/997567-1619f9fbf5ea69.png"
    },
    {
        "email": "reiner.conrad@selectcode.de",
        "name": "Reiner Conrad",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1009027-1604b7f1c6e7c4.png"
    },
    {
        "email": "anton.steuer@selectcode.de",
        "name": "Anton Steuer",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1022643-16107f3686b730.png"
    },
    {
        "email": "kerim.anater@selectcode.de",
        "name": "Kerim Anater",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1023697-16193f23721b49.jpg"
    },
    {
        "email": "nicole.list@selectcode.de",
        "name": "Nicole List",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1076387-162cd8fb2683f5.png"
    },
    {
        "email": "florian.glombik@selectcode.de",
        "name": "Florian Glombik",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1087983-16310ecd0d8dd4.png"
    },
    {
        "email": "dario.schmid@selectcode.de",
        "name": "Dario Schmid",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1089359-16177ae47a6708.png"
    },
    {
        "email": "thivya.kanakasabesan@selectcode.de",
        "name": "Thivya Kanakasabesan",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1110033-162908bbf8c2a4.jpg"
    },
    {
        "email": "gereon.elvers@selectcode.de",
        "name": "Gereon Elvers",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1123567-16226063758f6c.png"
    },
    {
        "email": "julian.hartl@selectcode.de",
        "name": "Julian Hartl",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1143967-1625c59876ea6a.png"
    },
    {
        "email": "david.fiedler@selectcode.de",
        "name": "David Fiedler",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1148819-162a1e75e57c9d.jpg"
    },
    {
        "email": "matthias.wolf@selectcode.de",
        "name": "Matthias Wolf",
        "avatar": "https://d36887svjhykt4.cloudfront.net/users/1153257-1624571d108d25.png"
    }
]
