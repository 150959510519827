import {State} from "./State";

export const url = "https://mvp-backend.selectcode.io/ns/kitchen/selectcode"

export const log_url = "https://mvp-backend.selectcode.io/ns/kitchen_logs"

export async function setState(state: State) {
    await fetch(url, {
        body: JSON.stringify(state),
        method: "POST"
    })
}

export async function addLog(email: string, log: string) {
    const d = new Date().toLocaleString()
    const a = Date.now()
    await fetch(log_url + "/" + a, {
        body: JSON.stringify({
            time: d,
            email,
            log
        }),
        method: "POST"
    })
}

