import React, {useEffect} from 'react';
import './App.css';
import {
    Avatar,
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Grid,
    NextUIProvider,
    Row,
    Spacer,
    Text
} from "@nextui-org/react";
import {ClerkProvider, RedirectToSignIn, SignedIn, SignedOut, UserButton, useUser,} from "@clerk/clerk-react";
import {useNavigate} from "react-router-dom";
import {users} from "./users";
import {addLog, log_url, setState, url} from "./api";
import useSWR, {mutate, SWRConfig} from 'swr'

import {State} from "./State";

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function NavBar() {
    return (
        <Card css={{h: "90vh"}}>
            <Card.Body>
                <Container>
                    <Text h6>
                        Cause nobody wants a dirty kitchen
                    </Text>
                    <img src="/coffe.gif" height={150} width={150}/>
                    <img src="/sink.gif" height={150} width={150}/>
                    <img src="/knive.gif" height={150} width={150}/>
                    <Spacer/>
                    <Text>You Profile:</Text>
                    <UserButton showName={true}/>
                </Container>
            </Card.Body>
        </Card>
    )
}

function Day(props: { day: string, email: string, days: State }) {
    const remind = () => {
        fetch("https://lucky-heron-67.deno.dev?email=" + props.email).catch(console.error)
        toast("Sent mail to " + props.email, {type: "success"})
    }

    return <Col><Card>
        <Card.Body>
            <Text h4>{props.day}</Text>
            <Spacer/>
            <UserSelector email={props.email} days={props.days} day={props.day}/>
            <Spacer/>
            <Button onClick={() => remind()}>
                Remind me!
            </Button>
        </Card.Body>
    </Card></Col>;
}

function UserSelector(props: { email: string, days: State, day: string }) {
    const activeUser = users.find(it => it.email === props.email) ?? {avatar: "", name: ""}

    const Ava = (props: { user: any }) => {
        return <Row align="center">
            <Avatar src={props.user.avatar} color="primary"/>
            <Text>{props.user.name}</Text>
        </Row>
    }

    const user = useUser()

    const handleSelection = async (key: any) => {
        let d = props.days
        //@ts-ignore
        d[props.day] = key
        await setState(d)
        await mutate(url)
        await addLog(user.user?.fullName ?? "", `Changed to person for ${props.day} to ${key}`)
        toast("Updated backend!", {type: "success"})
    }

    return <Row justify="center" align="center">
        <Dropdown>
            <Dropdown.Button flat>
                <Ava user={activeUser}/>
            </Dropdown.Button>
            <Dropdown.Menu aria-label="Static Actions" variant="light" onAction={handleSelection}>
                {users.map(it =>
                    <Dropdown.Item key={it.email} withDivider={true}>
                        <Ava user={it}/>
                    </Dropdown.Item>)}
            </Dropdown.Menu>

        </Dropdown>


        <Spacer/>
    </Row>
}

function KitchenDashboard() {
    const {data, error} = useSWR(url)

    if (!data) return <div/>

    const days = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"]

    return (
        <Card css={{h: "90vh", $$cardColor: '$colors$primary'}}>
            <Card.Body>
                <Container gap={0}>
                    <Spacer y={2}/>
                    <Text h1
                          css={{
                              textGradient: "45deg, $yellow600 -20%, $red600 100%",
                          }}>
                        KitchenPlanner
                    </Text>
                    <Spacer y={1}/>
                    <Row gap={1}>
                        {days.map(it => (<Day day={it} email={data[it]} days={data}/>))}
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

function LogContainer() {
    const {data} = useSWR<[string]>(log_url)
    if (!data) return <div/>

    return <div>
        <Text h1>Logs</Text>
        {data.map(it => <p>{it}</p>)}
    </div>
}

function Hello() {

    return <div className="App">
        <Grid.Container>
            <Grid.Container css={{h: "100%"}} gap={0} justify="center">
                <Grid xs={2}>
                    <NavBar/>
                </Grid>
                <Grid xs={10}>
                    <KitchenDashboard/>

                </Grid>
            </Grid.Container>
        </Grid.Container>
    </div>;
}


const frontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;

function App() {


    const navigate = useNavigate();


    return (
        <NextUIProvider>
            <SWRConfig
                value={{
                    refreshInterval: 1000,
                    fetcher: (resource, init) => fetch(resource, init).then(res => res.json())
                }}
            >
                <ClerkProvider frontendApi="clerk.relieved.teal-38.lcl.dev" navigate={(to) => navigate(to)}>
                    <SignedIn>
                        <Hello/>
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn/>
                    </SignedOut>
                </ClerkProvider>
            </SWRConfig>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </NextUIProvider>
    );
}

export default App;
